<template>
  <div id="MU_WeChatApplet">
    <top></top>
    <!-- 导出数据 -->
    <div class="vue-json-excel" v-if="Terminal_type == 'pc'">
      <template>
        <download-excel class="export-excel-wrapper" :data="data" :fields="json_fields" header="微信小程序东航选座收益报表"
          name="微信小程序东航选座.xls">
          <el-button type="success">数据导出</el-button>
        </download-excel>
      </template>
    </div>
    <div class="WeChat_body" :style="'height:' + table_height + 'px'">
      <table class="WeChat_table" border="1" style="table-layout: fixed">
        <tr>
          <td>姓名</td>
          <td>票号</td>
          <td>座位</td>
          <td v-if="Terminal_type == 'pc'">航段</td>
          <td v-if="Terminal_type == 'pc'">座位里程</td>
          <td v-if="Terminal_type == 'pc'">支付方式</td>
          <td v-if="Terminal_type == 'pc'">支付金额</td>
          <td v-if="Terminal_type == 'pc'">创建时间</td>
          <td v-if="Terminal_type == 'pc'">订单状态</td>
        </tr>
        <tr v-for="(item, index) in data" :key="index" @click="mobile(item, index)">
          <!-- 姓名 -->
          <td>{{item.name}}</td>
          <!-- 票号 -->
          <td>{{ item.airTicketNoForPage }}</td>
          <!-- 座位 -->
          <td>{{item.zuowei}}</td>
          <!-- 航段 -->
          <td v-if="Terminal_type == 'pc'">{{item.hangduan}}</td>
          <!-- 座位里程 -->
          <td v-if="Terminal_type == 'pc'">{{item.MU_pointAmount}}</td>
          <!--  -->
          <!-- 支付方式 -->
          <td v-if="Terminal_type == 'pc'">{{ item.pay_static}}</td>
          <!-- 支付金额 -->
          <td v-if="Terminal_type == 'pc'">{{ item.pay_money }}</td>
          <!-- 订单时间 -->
          <td v-if="Terminal_type == 'pc'">{{ item.created_time }}</td>
          <!-- 订单状态 -->
          <td :style="
                item.order_static == '待支付'
                  ? 'color:rgb(192 192 192);'
                  : 'color:green;'
              " v-if="Terminal_type == 'pc'">
            {{ item.order_static }}
          </td>
        </tr>
      </table>
    </div>
    <!-- mobile 独有-->
    <van-popup v-if="Terminal_type == 'mobile'" v-model="show" round position="bottom">
      <div class="WeChat_popup_div">
        <div class="WeChat_popup_flex">
          <p>姓名：</p>
          <p>{{popup_data.name}}</p>
        </div>
        <div class="WeChat_popup_flex">
          <p>票号：</p>
          <td>{{ popup_data.airTicketNoForPage }}</td>
        </div>
        <div class="WeChat_popup_flex">
          <p>座位：</p>
          <td>{{popup_data.zuowei}}</td>
        </div>
        <div class="WeChat_popup_flex">
          <p style="flex:3;">航段：</p>
          <p>{{popup_data.hangduan}}</p>
        </div>
        <div class="WeChat_popup_flex">
          <p>座位里程</p>
          <p>{{popup_data.MU_pointAmount}}</p>
        </div>
        <div class="WeChat_popup_flex">
            <p>支付方式</p>
            <p>{{ popup_data.pay_static}}</p>
          </div>
        <div class="WeChat_popup_flex">
          <p>支付金额：</p>
          <p>{{ popup_data.pay_money }}</p>
        </div>
        <div class="WeChat_popup_flex">
          <p>订单时间：</p>
          <p>{{ popup_data.created_time }}</p>
        </div>
        <div class="WeChat_popup_flex">
          <p>订单状态：</p>
          <p :style="
                    popup_data.order_static == '待支付'
                      ? 'color:rgb(192 192 192);'
                      : 'color:green;'
                  ">
            {{ popup_data.order_static }}
          </p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import top from "../components/BodyComponents.vue";
  export default {
    name: "SouthernOrder",
    data() {
      return {
        table_height: sessionStorage.getItem("table_height"),
        data: [],
        json_fields: {
          姓名: "name",
          票号: "airTicketNoForPage",
          座位: "zuowei",
          座位里程: 'MU_pointAmount',
          支付方式: 'pay_static',
          支付金额: "pay_money",
          创建时间: "created_time",
          订单状态: "pay_static",
        },
        // DetailsForm: [],
        show: false,
        popup_data: [],
        data_index: 0,
      }
    },
    components: {
      top,
    },
    created() {
      this.$https('selection/ren/mu_order', '', 'post').then(res => {
        this.data = res.data.data
      })
    },
    methods: {
      // mobile 独有
      mobile(tr_item, tr_index) {
        if (this.Terminal_type == "pc") return false;
        this.show = true;
        this.popup_data = tr_item;
        this.data_index = tr_index;
        console.log(tr_item);
      },
    },
  }

</script>

<style scoped>


</style>
